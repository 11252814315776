/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import debounce from "../../node_modules/throttle-debounce/throttle.js";

$(async function () {

	// Responsive menu
	$('header').each(function () {
		var element = $(this),
			body = $(document.body),
			responsiveNav = $('.responsive-nav');

		var toggler = $('.toggler').click(function () {
			//body.toggleClass('open');
			responsiveNav.toggleClass('-collapsed');
			toggler.toggleClass('-closed');
		});
	});

	// Filter
	$('#product-form, #recipe-form').each(async function () {
		await import("jquery-ajax-unobtrusive");

		var form = $(this);
		var submit = debounce(300, function () { form.submit() });

		form.find('input[type="checkbox"]').change(submit);
		form.find('.-textbox input').keypress(submit);

		form.find('#btnSubmit').css("display", "none");
	});

	// Dropdown 
	$(".dropdown").each(async function () {
		await import("../../node_modules/responsive-bp/src/js/responsive.core.js");
		await import("../../node_modules/responsive-bp/src/js/responsive.dropdown.js");

		// Fix for link in the dropdown part
		$(this).find('a').on('click', function (event) { event.stopPropagation(); })
	});

	// Match height
	var matchHeight = $('.match-height');
	if (matchHeight.length) {
		await import('jquery-match-height');

		matchHeight.matchHeight({
			property: 'height'
		});
	}

	// product text 
	$('.fading-text').each(function () {
		var element = $(this);

		if (element.height() > 300)
		{
			var expandText = element.data('expand-text');
			var contractText = element.data('contract-text');
			element.addClass('-contract');

			var toggler = $('<span class="fading-text__toggler link" data-expand-text="' + expandText + '" data-contract-text="' + contractText + '"></span>').click(function () {
				element.toggleClass('-contract');
			});

			element.after(toggler);
		}
	});
	
	// Slideshow
	$('.slideshow').each(async function () {
		await import('slick-carousel');

		$(this).slick({
			arrows: false,
			dots: false,
			fade: true,
			autoplay: true,
			speed: 1500,
			autoplaySpeed: 4000
		});
	});

	// product slider
	$('.product-slider').each(async function () {
		await import('slick-carousel');

		$(this).slick({
			arrows: true,
			dots: false,
			fade: false,
			autoplay: true,
			speed: 1500,
			autoplaySpeed: 4000,
			slidesToShow: 5,
			responsive: [
				{
					breakpoint: 1440,
					settings: {
						slidesToShow: 4
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 426,
					settings: {
						slidesToShow: 1
					}
				}]
		});
	});

	// recipe slider
	$('.recipe-slider').each(async function () {
		await import('slick-carousel');

		$(this).slick({
			arrows: true,
			dots: false,
			fade: false,
			autoplay: true,
			speed: 1500,
			autoplaySpeed: 4000,
			slidesToShow: 3,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1
					}
				}]
		});
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		//await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});

	// Jump to submitted form
	$('.is-contact-form:has(form)').each(function () {
		var $this = $(this),
			$form = $this.find('form'),
			anchor = $this.find('.anchor').attr('name'),
			button = $this.find('.button');

		if (anchor === undefined) {
			anchor = $this.attr('id');
		}

		$form.attr('action', $form.attr('action') + '#' + anchor);

		$form.on('submit', function () {
			button.addClass('is-loading');
		});
	});
});